























































import { UserFilter } from "@/models/user-filter.model";
import Vue from "vue";
import Component from "vue-class-component";
import Vuetable from "vuetable-2";
import { namespace } from "vuex-class";
import * as _ from "lodash";

const userFiltersNamespace = namespace("UserFilters");

@Component({ components: { Vuetable } })
export default class UserFilters extends Vue {
  fields = [{ name: "name", sortField: "name" }, { name: "actions" }];
  isLoading = false;

  @userFiltersNamespace.Getter("all")
  userFilters!: UserFilter[];

  @userFiltersNamespace.Action("fetchAll")
  fetchAll!: () => Promise<UserFilter>;

  @userFiltersNamespace.Action("delete")
  delete!: (id: string) => Promise<UserFilter>;

  @userFiltersNamespace.Action("create")
  createUserFilter!: (filter: UserFilter) => Promise<UserFilter>;

  get locale() {
    return this.$root.$i18n.locale;
  }

  dataManager(sortOrder, pagination) {
    if (this.userFilters.length < 1) return;

    let local = this.userFilters;

    // sortOrder can be empty, so we have to check for that as well
    if (sortOrder.length > 0) {
      local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction);
    }

    return {
      pagination: pagination,
      data: local,
    };
  }

  addUserFilter() {
    this.$router.push({ name: "user-filters-detail-create" });
  }

  editUserFilter(userFilter: UserFilter) {
    this.$router.push({
      name: "user-filters-detail",
      params: { id: userFilter.id },
    });
  }

  async duplicateUserFilter(filterToCopy: UserFilter) {
    this.isLoading = true;
    let filter = new UserFilter();
    const { id, ...filterContent } = filterToCopy;
    filter = { ...filterContent, id: "" };
    filter.name = `${filter.name} (copy)`;
    await this.createUserFilter(filter);
    this.$root.$bvToast.toast("User group duplicated!", {
      variant: "success",
    });
    this.doFetchAll();
    this.isLoading = false;
  }

  async deleteUserFilter(userFilter: UserFilter) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${userFilter.name}?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.delete(userFilter.id);
    }
    this.isLoading = false;
    this.doFetchAll();
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  async created() {
    await this.doFetchAll();
  }
}
